.home {
    display: flex;
    align-items: center;
    cursor: pointer;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .home:hover {
    
    border-radius: 30px;
    color: var(--twitter-color);
    transition: color 100ms ease-out;
  }
  
  .home > .makeStyles-cardGrid-12-root {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .home > .makeStyles-cardGrid-4 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .home > .makeStyles-cardGrid-6 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .home > .jss16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .home > .jss4 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .home > .jss6 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .home > .jss8 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .home > .jss12 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  
  .home > h2 {
    font-weight: 800;
    font-size: 20px;
    margin-right: 20px;
  }
  
  .home--active {
    color: #50b7f5;
  }
  /* On Chrome */
.home::-webkit-scrollbar {
  display: none;
}
.home > .jss224 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.home > .jss284 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.home > .jss24 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.home > .jss44 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.home > .MuiContainer-root
{
  padding-top: 16px;
  padding-bottom: 16px;
}