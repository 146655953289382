*{
    margin: 0;
    padding: 0;
    font-family: 'DM Sans',sans-serif;
}

body{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.container{
    padding: 30px 0px;
}
.pricing-card-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}
.card{
    border: 1.5px solid #e1e3e8;
    border-radius: 10px;
    padding: 20px;
    width: 290px;
}
.card .upper-part{
    text-align: center;
}
.upper-part h2{
    color: #332758;
}
.upper-part .discount{
    margin-top: 2rem;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
}
.discount small{
    color: #95a0bd;
    text-decoration: line-through;
    font-weight: 600;
    font-size: 20px;
    border-radius: 20px;
}
.discount span{
    background-color: #eae4fe;
    color: #673ee5;
    padding: 5px 15px;
    font-weight: 600;
    font-size: 13px;
    border-radius: 20px;
}
.price{
    margin-top: 1rem;
}
.price h3{
    color: #332758;
    font-size: 15px;
}
.price span{
    font-size: 45px;
    font-weight: 700;
}
.upper-part button{
    border: none;
    color: #fff;
    background-color: #673ee5;
    width: 80%;
    padding: 10px 0px;
    border-radius: 5px;
    margin-top: 3rem;
    cursor: pointer;
}
.upper-part p{
    color: #545558;
    font-size: 12px;
    margin: 1rem 0rem;
}
.card hr{
    border: none;
    height: 2px;
    background-color: #e1e3e8;
}
.feature{
    margin-top: 1rem;
}
.feature h4{
    color: #332758;
    margin-bottom: 1rem;
    font-size: 18px;
}
.feature ul li{
    list-style: none;
    margin-top: 0.3rem;
}
.feature span{
    color: #332758;
    font-weight: 700;
}
ul li i{
    color: #71b770;
    margin-right: 0.5rem;
}
.yellow{
    color: #e3cc70;
}
.dropdown-btn{
    margin-top: 1rem;
    text-align: center;
}
.dropdown-btn a{
    text-decoration: none;
    font-weight: 700;
    font-size: 1rem;
}
.dropdown-btn i{
    margin-left: 0.1rem;
    font-size: 13px;
}
.card-2{
    position: relative;
}
.card-2 .badge{
    position: absolute;
    top: -15px;
    left: 65px;
    background-color: #fb5185;
    border-radius: 20px;
    padding: 5px 50px;
    color: #fff;
    font-weight: 500;
}
.card-2 .discount span{
    color: #fb5185;
    background-color: #fee9ee;
}
.card-2 .price,.card-3 .price{
    margin-bottom: 1.5rem;
}
.card-2 .free{
    color: #fb5185;
    font-weight: 600;
    font-size: 1.1rem;
}
.card-2 button{
    margin-top: 0.5rem;
    background-color: #fb5185;
}

.card-3 .free{
    color: #673ee5;
    font-weight: 600;
    font-size: 1.1rem;
}
.card-3 button{
    margin-top: 0.5rem;
}
.card:hover{
    box-shadow: 3px 5px 10px #e1e3e8;
    transform: scale(1.03,1.03);
    transition: 0.4s;
}