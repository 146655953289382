
/* Main Chat Container */
.app-container {
    width: 100%;;
    height: 100%; /* Full screen */   
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
    overflow: hidden;
    margin-bottom: 80px; /* Space for fixed footer */
    justify-content: flex-start;
    align-items: center;
  }
  .chat-container {
    width: 100%;;
    height: 100%; /* Full screen */
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
    overflow: hidden;
    margin-bottom: 80px; /* Space for fixed footer */
    justify-content: flex-start;
    align-items: center;
  }
  
  /* Chatbot Icon Wrapper */
  .chatbot-icon-wrapper {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
  }
  
  .chatbot-icon {
    width: 67px;
    height: 67px;
    margin-top:0.1px;
    border-radius: 50%;
    background-color: #f5f5f5;
    border: 1px solid #242424;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    cursor: pointer;
  }
  
  /* AI Doctor logo text */
  .ai-doctor-logo {
    font-size: 14px;
    font-weight: bold;
    margin-top: 0.1px;
    color: #333;
    text-transform: uppercase;
  }
  
  /* Chat Window */
  .chat-window {
    flex-grow: 1;
    width: 100%;
    height:100%;
    overflow-y: auto;
    padding: 10px 30px;
    border-radius: 12px;
    scrollbar-width: thin;
    scrollbar-color: #f5f5f5 #f5f5f5;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  /* Messages */
  .user-box, .bot-box {
    max-width: 75%;
    margin: 8px 0;
    padding: 10px;
    border-radius: 16px;
    position: relative;
    font-size: 14px;
    word-wrap: break-word;
    line-height: 1.4;
  }
  
  .user-box {
    background-color: rgb(83, 120, 107);
    color: white;
    border-radius: 20px;
    align-self: flex-start;
    border-top-left-radius: 0;
  }
  
  .bot-box {
    background-color: #888;
    color: white;
    align-self: flex-end;
    border-top-right-radius: 0;
  }
  
  /* Timestamp */
  .timestamp {
    font-size: 12px;
    color: white;
    text-align: right;
    margin-top: 5px;
  }
  
  /* Chat Input Wrapper */
  .chat-input-wrapper {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 1; /* Fixed at the bottom of the screen */
    left: 0;
    width: 100%;
    padding: 10px 20px;
    background-color: #ffffff;
    z-index: 1000;
    justify-content: center; /* Center input and mic button */
  }
  
  /* Chat Input */
  .chat-input {
    flex-grow: 1;
    margin-top: 5px;;
    width: 100%;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 25px;
    border: 1px solid #333;
    background: #fafafa;
    color: #333;
    outline: none;
    margin-right:150px;
    transition: border-color 0.3s ease;
    position: relative;
    max-width: 100%; /* Adjust for screen width */
  }
  
  .chat-input:focus {
    border-color: #333;
  }
  
  /* Microphone Button */
  .mic-button {
    flex-grow: 1;
    width: 50px;  /* Adjust width for a circular button */
    height: 50px;
    background-color:transparent;
    border: 0.5px solid #ccc ;
    margin-right: 30px;
    margin-top: 8px;;
    padding: 14px;
    cursor: pointer;
    font-size: 1.5rem;
    transition: border 0.3s ease;
    position: absolute;
    right: 10px; /* Inside the input box, on the right side */
    outline: none;
    box-shadow:none ; /* Removed any shadow to make it more flat */
    border-radius: 50%; /* Remove the round boundary */
  }
  
  .mic-button.clicked {
    border: 3cqb solid green/* Add a glowing green border around the mic */
  }
  .mic-button.clicked svg {
    color: green !important;  /* Change the microphone icon color to green */
  }
  .mic-button svg {
    font-size: 24px;  /* Adjust size of the microphone icon */
    color: green;  /* Set color of the icon */
    transition: color 0.3s ease;  /* Smooth transition for color change */
  }
  
  .mic-button.active {
    background-color: #f0f0f0;  /* Optional: background change when active */
  }
  
  /* Footer */
  .footer {
    text-align: center;
    padding: 20px;
    background-color: #242424;
    margin-top: 50px;
    width: 100%;
  }
  
  /* Custom Scrollbar */
  .chat-window::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }
  
  .chat-window::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 4px;
  }
  
  @keyframes hoverEffect {
    from { transform: scale(1); }
    to { transform: scale(1.1); }
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }