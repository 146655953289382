main {
    width: 70%;
    margin: auto;
  }
  
  main button {
    margin-top: 20px;
    background-color: #2D8CFF;
    color: #ffffff;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    display: inline-block;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    outline: none;
  }
  
  main button:hover {
    background-color: #2681F2;
  }

  input[type=text]:focus {
    outline: none
  }