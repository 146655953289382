@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

.container {
  max-width: 1990px;
  margin: 1px auto;
  overflow: auto;
  min-height: 300px;
  padding: 1px;
  border-radius: 5px;
  scrollbar-width: none;
    -ms-overflow-style: none;
}
.container::-webkit-scrollbar {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn:focus {
  outline: none;
}

.btn:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.task {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.task.reminder {
  border-left: 5px solid green;
}

.task h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1 1;
}

.form-control-check input {
  flex: 2 1;
  height: 20px;
}

footer {
  margin-top: 20px;
  text-align: center;
}

input[type=text]:focus {
    outline: none;
  }
.home {
    display: flex;
    align-items: center;
    cursor: pointer;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .home:hover {
    
    border-radius: 30px;
    color: var(--twitter-color);
    transition: color 100ms ease-out;
  }
  
  .home > .makeStyles-cardGrid-12-root {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .home > .makeStyles-cardGrid-4 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .home > .makeStyles-cardGrid-6 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .home > .jss16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .home > .jss4 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .home > .jss6 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .home > .jss8 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .home > .jss12 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  
  .home > h2 {
    font-weight: 800;
    font-size: 20px;
    margin-right: 20px;
  }
  
  .home--active {
    color: #50b7f5;
  }
  /* On Chrome */
.home::-webkit-scrollbar {
  display: none;
}
.home > .jss224 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.home > .jss284 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.home > .jss24 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.home > .jss44 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.home > .MuiContainer-root
{
  padding-top: 16px;
  padding-bottom: 16px;
}
.tweetBox {
  padding-bottom: 10px;
  border-bottom: 8px solid var(--twitter-background);
  padding-right: 10px;
}

.tweetBox > form {
  display: flex;
  flex-direction: column;
}

.tweetBox__input {
  padding: 5px;
  display: flex;
}

.tweetBox__input > input {
  flex: 1 1;
  padding: 10px;
  margin-left: 10px;
  font-size: 16px;
  border: none;
}

.tweetBox__imageInput {
  padding: 5px;
  display: flex;
}

.tweetBox__imageInput > input {
  flex: 1 1;
  margin-left: 10px;
  font-size: 14px;
  border: none;
}

.tweetBox__tweetButton {
  background-color: #f5f6f7 !important;
  border: none !important;
  color: #4b4f56 !important;
  font-weight: 900 !important;
  text-transform: inherit !important;
  border-radius: 30px !important;
  width: 80px;
  height: 40px !important;
  margin-top: 20px !important;
  margin-left: auto !important;
}
.header  {
  display: flex;
}
.header--active {
    color: #50b7f5;
  }

.header > a {
    color: grey;
  }
.header__peoplefinder {
    padding: 5px;
    display: flex;
    margin-right: 10px;
    float:right
  }
.header__logout {
    padding: 5px;
    display: flex;
    margin-right: 10px;
    float:right
  }
.post {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid var(--twitter-background);
  padding-bottom: 5px;
}

.post__body {
  flex: 1 1;
  padding: 5px;
}

.post__body > img {
  border-radius: 10px;
}

.post__header {
  width: "100%"
}
.post__footer {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.post__headerDescription {
  margin-bottom: 5px;
  font-size: 15px;
}


.post__headerText > h3 {
  font-size: 15px;
  margin-bottom: 5px;
}

.post__badge {
  font-size: 14px !important;
  color: #50b7f5;
}
.post__icon {
  margin-right: 0;
  float: right;
}
.post__icon:hover {
  background-color: #e8f5fe;
  border-radius: 30px;
  color: var(--twitter-color);
  transition: color 100ms ease-out;
}
.post__headerSpecial {
  font-weight: 600;
  font-size: 12px;
  color: gray;
}
.post__likes {
  font-weight: 600;
  font-size: 12px;
  color: gray;
  flex: 0 0 auto;
  padding: 6px;
  overflow: visible;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  margin-left: 0px;
}
.post__dislikes {
  font-weight: 600;
  font-size: 12px;
  color: gray;
  flex: 0 0 auto;
  padding: 6px;
  overflow: visible;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  margin-left: 0px;
}
.post__comments {
  font-weight: 600;
  font-size: 12px;
  color: gray;
  flex: 0 0 auto;
  padding: 6px;
  overflow: visible;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  margin-left: 0px;
}
.post__downloads {
  font-weight: 600;
  font-size: 12px;
  color: gray;
  flex: 0 0 auto;
  padding: 6px;
  overflow: visible;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  margin-left: 0px;
}
.post__avatar {
  padding: 5px;
}
.post__comment {
  padding: 5px;
  display: flex;
}

.post__comment > input {
  flex: 1 1;
  margin-left: 10px;
  font-size: 14px;
  border: none;
}

.post > .react-pdf__Page__canvas {
  width: 100%;
}
.feed {

  border-right: 1px solid var(--twitter-background);
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.feed::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.feed {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.feed__header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  border: 1px solid var(--twitter-background);
  padding: 15px 20px;
}

.feed__header > h2 {
  font-size: 20px;
  font-weight: 800;
}
main {
    width: 70%;
    margin: auto;
  }
  
  main button {
    margin-top: 20px;
    background-color: #2D8CFF;
    color: #ffffff;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    display: inline-block;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    outline: none;
  }
  
  main button:hover {
    background-color: #2681F2;
  }

  input[type=text]:focus {
    outline: none
  }
*{
    margin: 0;
    padding: 0;
    font-family: 'DM Sans',sans-serif;
}

body{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.container{
    padding: 30px 0px;
}
.pricing-card-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
}
.card{
    border: 1.5px solid #e1e3e8;
    border-radius: 10px;
    padding: 20px;
    width: 290px;
}
.card .upper-part{
    text-align: center;
}
.upper-part h2{
    color: #332758;
}
.upper-part .discount{
    margin-top: 2rem;
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
}
.discount small{
    color: #95a0bd;
    text-decoration: line-through;
    font-weight: 600;
    font-size: 20px;
    border-radius: 20px;
}
.discount span{
    background-color: #eae4fe;
    color: #673ee5;
    padding: 5px 15px;
    font-weight: 600;
    font-size: 13px;
    border-radius: 20px;
}
.price{
    margin-top: 1rem;
}
.price h3{
    color: #332758;
    font-size: 15px;
}
.price span{
    font-size: 45px;
    font-weight: 700;
}
.upper-part button{
    border: none;
    color: #fff;
    background-color: #673ee5;
    width: 80%;
    padding: 10px 0px;
    border-radius: 5px;
    margin-top: 3rem;
    cursor: pointer;
}
.upper-part p{
    color: #545558;
    font-size: 12px;
    margin: 1rem 0rem;
}
.card hr{
    border: none;
    height: 2px;
    background-color: #e1e3e8;
}
.feature{
    margin-top: 1rem;
}
.feature h4{
    color: #332758;
    margin-bottom: 1rem;
    font-size: 18px;
}
.feature ul li{
    list-style: none;
    margin-top: 0.3rem;
}
.feature span{
    color: #332758;
    font-weight: 700;
}
ul li i{
    color: #71b770;
    margin-right: 0.5rem;
}
.yellow{
    color: #e3cc70;
}
.dropdown-btn{
    margin-top: 1rem;
    text-align: center;
}
.dropdown-btn a{
    text-decoration: none;
    font-weight: 700;
    font-size: 1rem;
}
.dropdown-btn i{
    margin-left: 0.1rem;
    font-size: 13px;
}
.card-2{
    position: relative;
}
.card-2 .badge{
    position: absolute;
    top: -15px;
    left: 65px;
    background-color: #fb5185;
    border-radius: 20px;
    padding: 5px 50px;
    color: #fff;
    font-weight: 500;
}
.card-2 .discount span{
    color: #fb5185;
    background-color: #fee9ee;
}
.card-2 .price,.card-3 .price{
    margin-bottom: 1.5rem;
}
.card-2 .free{
    color: #fb5185;
    font-weight: 600;
    font-size: 1.1rem;
}
.card-2 button{
    margin-top: 0.5rem;
    background-color: #fb5185;
}

.card-3 .free{
    color: #673ee5;
    font-weight: 600;
    font-size: 1.1rem;
}
.card-3 button{
    margin-top: 0.5rem;
}
.card:hover{
    box-shadow: 3px 5px 10px #e1e3e8;
    -webkit-transform: scale(1.03,1.03);
            transform: scale(1.03,1.03);
    transition: 0.4s;
}

/* Main Chat Container */
.app-container {
    width: 100%;;
    height: 100%; /* Full screen */   
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
    overflow: hidden;
    margin-bottom: 80px; /* Space for fixed footer */
    justify-content: flex-start;
    align-items: center;
  }
  .chat-container {
    width: 100%;;
    height: 100%; /* Full screen */
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
    overflow: hidden;
    margin-bottom: 80px; /* Space for fixed footer */
    justify-content: flex-start;
    align-items: center;
  }
  
  /* Chatbot Icon Wrapper */
  .chatbot-icon-wrapper {
    position: fixed;
    top: 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
  }
  
  .chatbot-icon {
    width: 67px;
    height: 67px;
    margin-top:0.1px;
    border-radius: 50%;
    background-color: #f5f5f5;
    border: 1px solid #242424;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    cursor: pointer;
  }
  
  /* AI Doctor logo text */
  .ai-doctor-logo {
    font-size: 14px;
    font-weight: bold;
    margin-top: 0.1px;
    color: #333;
    text-transform: uppercase;
  }
  
  /* Chat Window */
  .chat-window {
    flex-grow: 1;
    width: 100%;
    height:100%;
    overflow-y: auto;
    padding: 10px 30px;
    border-radius: 12px;
    scrollbar-width: thin;
    scrollbar-color: #f5f5f5 #f5f5f5;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  /* Messages */
  .user-box, .bot-box {
    max-width: 75%;
    margin: 8px 0;
    padding: 10px;
    border-radius: 16px;
    position: relative;
    font-size: 14px;
    word-wrap: break-word;
    line-height: 1.4;
  }
  
  .user-box {
    background-color: rgb(83, 120, 107);
    color: white;
    border-radius: 20px;
    align-self: flex-start;
    border-top-left-radius: 0;
  }
  
  .bot-box {
    background-color: #888;
    color: white;
    align-self: flex-end;
    border-top-right-radius: 0;
  }
  
  /* Timestamp */
  .timestamp {
    font-size: 12px;
    color: white;
    text-align: right;
    margin-top: 5px;
  }
  
  /* Chat Input Wrapper */
  .chat-input-wrapper {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 1; /* Fixed at the bottom of the screen */
    left: 0;
    width: 100%;
    padding: 10px 20px;
    background-color: #ffffff;
    z-index: 1000;
    justify-content: center; /* Center input and mic button */
  }
  
  /* Chat Input */
  .chat-input {
    flex-grow: 1;
    margin-top: 5px;;
    width: 100%;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 25px;
    border: 1px solid #333;
    background: #fafafa;
    color: #333;
    outline: none;
    margin-right:150px;
    transition: border-color 0.3s ease;
    position: relative;
    max-width: 100%; /* Adjust for screen width */
  }
  
  .chat-input:focus {
    border-color: #333;
  }
  
  /* Microphone Button */
  .mic-button {
    flex-grow: 1;
    width: 50px;  /* Adjust width for a circular button */
    height: 50px;
    background-color:transparent;
    border: 0.5px solid #ccc ;
    margin-right: 30px;
    margin-top: 8px;;
    padding: 14px;
    cursor: pointer;
    font-size: 1.5rem;
    transition: border 0.3s ease;
    position: absolute;
    right: 10px; /* Inside the input box, on the right side */
    outline: none;
    box-shadow:none ; /* Removed any shadow to make it more flat */
    border-radius: 50%; /* Remove the round boundary */
  }
  
  .mic-button.clicked {
    border: 3cqb solid green/* Add a glowing green border around the mic */
  }
  .mic-button.clicked svg {
    color: green !important;  /* Change the microphone icon color to green */
  }
  .mic-button svg {
    font-size: 24px;  /* Adjust size of the microphone icon */
    color: green;  /* Set color of the icon */
    transition: color 0.3s ease;  /* Smooth transition for color change */
  }
  
  .mic-button.active {
    background-color: #f0f0f0;  /* Optional: background change when active */
  }
  
  /* Footer */
  .footer {
    text-align: center;
    padding: 20px;
    background-color: #242424;
    margin-top: 50px;
    width: 100%;
  }
  
  /* Custom Scrollbar */
  .chat-window::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }
  
  .chat-window::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 4px;
  }
  
  @-webkit-keyframes hoverEffect {
    from { -webkit-transform: scale(1); transform: scale(1); }
    to { -webkit-transform: scale(1.1); transform: scale(1.1); }
  }
  
  @keyframes hoverEffect {
    from { -webkit-transform: scale(1); transform: scale(1); }
    to { -webkit-transform: scale(1.1); transform: scale(1.1); }
  }
  
  @-webkit-keyframes fadeIn {
    from { opacity: 0; -webkit-transform: translateY(10px); transform: translateY(10px); }
    to { opacity: 1; -webkit-transform: translateY(0); transform: translateY(0); }
  }
  
  @keyframes fadeIn {
    from { opacity: 0; -webkit-transform: translateY(10px); transform: translateY(10px); }
    to { opacity: 1; -webkit-transform: translateY(0); transform: translateY(0); }
  }
