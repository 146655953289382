.post {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid var(--twitter-background);
  padding-bottom: 5px;
}

.post__body {
  flex: 1;
  padding: 5px;
}

.post__body > img {
  border-radius: 10px;
}

.post__header {
  width: "100%"
}
.post__footer {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.post__headerDescription {
  margin-bottom: 5px;
  font-size: 15px;
}


.post__headerText > h3 {
  font-size: 15px;
  margin-bottom: 5px;
}

.post__badge {
  font-size: 14px !important;
  color: #50b7f5;
}
.post__icon {
  margin-right: 0;
  float: right;
}
.post__icon:hover {
  background-color: #e8f5fe;
  border-radius: 30px;
  color: var(--twitter-color);
  transition: color 100ms ease-out;
}
.post__headerSpecial {
  font-weight: 600;
  font-size: 12px;
  color: gray;
}
.post__likes {
  font-weight: 600;
  font-size: 12px;
  color: gray;
  flex: 0 0 auto;
  padding: 6px;
  overflow: visible;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  margin-left: 0px;
}
.post__dislikes {
  font-weight: 600;
  font-size: 12px;
  color: gray;
  flex: 0 0 auto;
  padding: 6px;
  overflow: visible;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  margin-left: 0px;
}
.post__comments {
  font-weight: 600;
  font-size: 12px;
  color: gray;
  flex: 0 0 auto;
  padding: 6px;
  overflow: visible;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  margin-left: 0px;
}
.post__downloads {
  font-weight: 600;
  font-size: 12px;
  color: gray;
  flex: 0 0 auto;
  padding: 6px;
  overflow: visible;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  margin-left: 0px;
}
.post__avatar {
  padding: 5px;
}
.post__comment {
  padding: 5px;
  display: flex;
}

.post__comment > input {
  flex: 1;
  margin-left: 10px;
  font-size: 14px;
  border: none;
}

.post > .react-pdf__Page__canvas {
  width: 100%;
}