.header  {
  display: flex;
}
.header--active {
    color: #50b7f5;
  }

.header > a {
    color: grey;
  }
.header__peoplefinder {
    padding: 5px;
    display: flex;
    margin-right: 10px;
    float:right
  }
.header__logout {
    padding: 5px;
    display: flex;
    margin-right: 10px;
    float:right
  }